/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "../src/assets/fonts/RemixIcon/remixicon.css";
body.bg {
  background: url("/assets/images/main-home-banner.jpg") no-repeat center center;
  background-size: cover;
}

.form-group {
  display: block;
  position: relative;
  margin-bottom: 20px;

  label {
    font-size: 12px;
    color: #444;
  }
}
.form-control {
  width: 100%;
  border: 1px solid #cccbcb;
  box-shadow: 3px 3px 10px 3px #cacaca;
  border-radius: 5px;
  line-height: 1.2;
  padding: 8px;
}
